import { AIStatFields } from '@/hooks/content/types';
import { Tile } from '@/components/Tile';
import clsx from 'clsx';

export const ContentBlock = ({ title, input, bottomText, className }: AIStatFields & { className?: string }) => {
  return (
    <Tile className={clsx('flex flex-col p-2 gap-y-2 w-full', className)}>
      <div className="bg-neutral-950 rounded p-4 flex flex-col">
        <p className="text-neutral-600 uppercase mb-2 text-xs font-medium">{title}</p>
        <p className="text-neutral-100 text-[32px] font-bold leading-none">{input}</p>
      </div>
      <p className="text-right text-neutral-600 text-xs">{bottomText}</p>
    </Tile>
  );
};
