export enum QueryKeys {
  EPOCH_DATA = 'EPOCH_DATA',
  USER_DATA = 'USER_DATA',
  ACTIVITY_CARDS = 'ACTIVITY_CARDS',
  ADDRESS_ACTIVITY = 'ADDRESS_ACTIVITY',
  INVITE_CODE = 'INVITE_CODE',
  PRECHECK_POINTS = 'PRECHECK_POINTS',
  GEO_LOCATION = 'GEO_LOCATION',
  ALLOWANCE = 'ALLOWANCE',
  SESSION = 'SESSION',
  BRIDGE_MESSAGE = 'BRIDGE_MESSAGE',
  DEVELOPERS_DASHBOARD = 'DEVELOPERS_DASHBOARD',
  RECENT_TRANSACTIONS = 'RECENT_TRANSACTIONS',
  CLAIMABLE_LIST = 'CLAIMABLE_LIST',
  ECOSYSTEM_APPS = 'ECOSYSTEM_APPS',
  RESTAKING_BANNERS = 'RESTAKING_BANNERS',
  ASSETS = 'ASSETS',
  REFERRED_POINTS = 'REFERRED_POINTS',
  THIRD_PARTY_BRIDGES = 'THIRD_PARTY_BRIDGES',
  REFERRED_USERS = 'REFERRED_USERS',
  UI_LOCALIZATION = 'UI_LOCALIZATION',
  POINTS = 'POINTS',
  CIRCULATING_POINTS = 'CIRCULATING_POINTS',
  GET_LINKS = 'GET_LINKS',
  ACCOUNT_MODE_DROP_DETAILS = 'ACCOUNT_MODE_DROP_DETAILS',
  MODE_STATISTICS = 'MODE_STATISTICS',
  MODE_DEV_DROPS = 'MODE_DEV_DROPS',
  LOCK_POSITIONS = 'LOCK_POSITIONS',
  FUNDS = 'FUNDS',
  LOCK_ALLOWANCE = 'LOCK_ALLOWANCE',
  APY_DATA = 'APY_DATA',
  WALLET_APP_POINTS = 'WALLET_APP_POINTS',
  TOP_USER_VESTED_AMOUNT = 'TOP_USER_VESTED_AMOUNT',
  NEWS = 'NEWS',
  USERS_READ_NEWS = 'USERS_READ_NEWS',
  TOKEN_BALANCES = 'TOKEN_BALANCES',
  FAQ_ITEMS = 'FAQ_ITEMS',
  GUIDES = 'GUIDES',
  DEVDROP_ELIGIBLE_USER = 'DEVDROP_ELIGIBLE_USER',
  ACTIVITY_POINTS = 'ACTIVITY_POINTS',
  EOA = 'EOA',
  HISTORY_POINTS_BY_PROTOCOL = 'HISTORY_POINTS_BY_PROTOCOL',
  HISTORY_POINTS_BY_WALLET = 'HISTORY_POINTS_BY_WALLET',
  SEASONAL_BANNERS = 'SEASONAL_BANNERS',
  ECOSYSTEM_DAPPS = 'ECOSYSTEM_DAPPS',
  MERKL_DAPPS = 'MERKL_DAPPS',
  ECOSYSTEM_CATEGORIES = 'ECOSYSTEM_CATEGORIES',
  COINGECKO_TOKEN_INFO = 'COINGECKO_TOKEN_INFO',
  OP_BALANCES = 'OP_BALANCES',
  STAKED_APY = 'STAKED_APY',
  STAKED_MODE_TOKENS = 'STAKED_MODE_TOKENS',
  STAKED_BPT_TOKENS = 'STAKED_BPT_TOKENS',
  ECOSYSTEM_DAPP_BANNER = 'ECOSYSTEM_DAPP_BANNER',
  AI_SECTIONS = 'AI_SECTIONS',
  GOVERNANCE_VOTE_STATUS = 'GOVERNANCE_VOTE_STATUS',
  MERKL_REWARDS = 'MERKL_REWARDS',
  MERKL_DAPPS_CAMPAIGNS = 'MERKL_DAPPS_CAMPAIGNS',
  GOVERNANCE_CALENDAR = 'GOVERNANCE_CALENDAR',
  REWARDS_BY_WALLET = 'REWARDS_BY_WALLET',
  STAKING_REWARDS_LINKS = 'STAKING_REWARDS_LINKS',
}
